<template>
  <div>
    <div>
      <header class="header">
        <div class="header-img">
          <img :src="avatar ? avatar : icon_member_default" alt="" />
        </div>
        <div class="header-text">{{ payInfo.staticCodeName || '' }}</div>

      </header>
      <div class="plus-input">
        <!-- ￥字符 货币的符号-->
        <div class="S-img">
          <img src="../../assets/icon/S.svg" alt="" />
        </div>

        <!-- 输入框光标 -->
        <!-- <div class="ttt">
        <div
          class="input-c-div"
          style="background: #07c160"
        ></div>
      </div> -->

        <!-- 手写输入框 -->
        <div class="input-c">
          <div class="input-c-div-1">{{ payOrderInfo.amount }}</div>
          <!-- <div class="input-c-div-1">
            <input type="text" v-model="payOrderInfo.amount" @focus="amountFocus" style="width: 300px">
          </div> -->

          <!-- 数字金额后边的光标 -->
          <!-- <div class="input-c-div" style="background:#07c160"></div> -->
        </div>
        <!-- 手写输入框的提示文字 -->
        <div v-show="!payOrderInfo.amount" class="placeholder">请输入金额</div>
      </div>
      <!-- <div class="plus-input" style="display: flex;flex-direction:column;">
        <div class="" style="font-size: 16px;text-align: left;">
          备注
        </div>
        <div class="input-c">
          <div>
            <input type="text" v-model="payOrderInfo.memo" @focus="memoFocus"
              style="width: 300px;font-size: 16px;font-weight: 500">
          </div>
        </div>
      </div> -->
      <ul class="plus-ul">
        <!-- 支付板块 -->
        <li style="border-radius:10px;">
          <!-- 支付金额板块 -->
          <div class="img-div">
            <img :src="wxImg" alt="" />
            <div class="div-text">
              微信支付
            </div>
          </div>
        </li>
      </ul>
      <!-- 备注板块 ，目前不需要添加备注，隐藏-->
      <div class="remark-k">
        <div class="remark">
          <div class="remark-hui" v-show="payOrderInfo.memo">{{ payOrderInfo.memo }}</div>
          <div @click="myDialogState = true">{{ payOrderInfo.memo ? "修改" : "添加备注" }}</div>
        </div>
      </div>
      <!-- dialog 对话框 目前不需要添加备注，隐藏-->
      <MyDialog v-show="myDialogState" @myDialogStateFn="myDialogStateFn" :remark="payOrderInfo.memo">
      </MyDialog>

      <!-- 键盘板块 目前不需要键盘 隐藏 -->
      <div class="keyboard-plus" v-if="isAllowModifyAmount">
        <Keyboard @delTheAmount="delTheAmount" @enterTheAmount="enterTheAmount" @payment="createOrder"
          :canpay="payOrderInfo.amount" :concealSate="concealSate"></Keyboard>
      </div>

      <!-- jeepay中，付款的点击事件 由 payment 修改为 pay  -->
      <!-- jeepay中，付款页面是唯一的，颜色不在需要v-bind，去掉即可 -->
      <!-- <div class="bnt-pay" v-if="!isAllowModifyAmount"> -->
      <!-- <div class="bnt-pay">
        <div class="bnt-pay-text" style="background-color:#07c160" @click="createOrder">
          付款
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
  import MyDialog from "./../dialog/dialog";  // 添加备注弹出的对话框
  import Keyboard from "./../keyboard/keyboard";  // 手写键盘
  import { getPayPackage, getPayOrderInfo, validPayQrcode, qrcodePayment, getChannelUserId, getRedirectUrl } from '@/api/api'
  import config from "@/config";
  import channelUserIdUtil from '@/utils/channelUserId'
  export default {
    // 注册备注对话框，和 手写键盘组件，由于这里是直接掉起支付事件，所以目前不应用
    components: { Keyboard, MyDialog },
    data: function () {
      return {
        isAllowModifyAmount: true,
        query: {},
        merchantName: 'jeepay',  // 付款的商户默认
        avatar: require("../../assets/images/doplogo.png"), // 商户头像默认
        amount: 1,  // 支付金额默认
        resData: {},
        wxImg: require("../../assets/icon/wx.svg"), // 微信支付图片
        payOrderInfo: {
          amount: ''
        }, //订单信息
        payInfo: {},
        content: '', // 输入内容
        concealSate: true,
        myDialogState: false
      }
    },

    mounted () {
      // this.setPayOrderInfo(true); //获取订单信息 & 调起支付插件

      if (localStorage.getItem('AUTHING') && localStorage.getItem('cacheToken') && channelUserIdUtil.getChannelUserId()) {
        console.log('正在授权且授权成功');
        this.payOrderInfo.amount = localStorage.getItem('amount') || null
        this.payOrderInfo.memo = localStorage.getItem('memo') || null
        console.log(this.payOrderInfo);
        // 正在授权且授权成功
        this.payMoney()
      } else {
        // 已授权完毕或已存在过openid
        console.log('正在授权且授权成功');
        this.query = this.$route.query
        console.log(this.query);
        this.handleValidPayQrcode()
      }
    },

    methods: {
      amountFocus () {
        this.concealSate = true
      },
      myDialogStateFn (val) {
        this.payOrderInfo.memo = val
        this.myDialogState = !this.myDialogState
      },
      delTheAmount (v) {
        this.enterTheAmount(v)
      },
      enterTheAmount (keys) {
        var content = this.content,
          len = content.length;

        switch (keys) {
          case '.': //点击小数点，（注意输入字符串里的是小数点，但是我界面显示的点不是小数点，是居中的点，在中文输入法下按键盘最左边从上往下数的第二个键，也就是数字键1左边的键可以打出居中的点）
            if (len < 11 && content.indexOf('.') == -1) { //如果字符串里有小数点了，则不能继续输入小数点，且控制最多可输入10个字符串
              if (content.length < 1) { //如果小数点是第一个输入，那么在字符串前面补上一个0，让其变成0.
                content = '0.';
              } else { //如果不是第一个输入小数点，那么直接在字符串里加上小数点
                content += '.';
              }
            }
            break;
          case 0:
            console.log(content)
            if (len < 4) {
              console.log(content.length)
              if (content.length < 1) { //如果0是第一个输入，让其变成0.
                content = '0.';
              } else {
                content += '0'
              }
            }
            if (content.indexOf('.') === -1 && len > 3 && len < 11) {
              content += '0'
            }
            if (content.indexOf('.') !== -1 && content.split('.')[1].length < 2 && len > 3 && len < 11) {
              content += '0'
            }
            break;
          case 'del': //如果点击删除键就删除字符串里的最后一个
            if (content === '0.') {
              content = ''
            } else {
              content = content.substr(0, content.length - 1);
            }
            break;
          default:
            // let Index = content.indexOf('.'); //小数点在字符串中的位置
            if (content.indexOf('.') === -1 || len - content.indexOf('.') !== 3) { //这里控制小数点只保留两位
              if (len < 11) { //控制最多可输入10个字符串
                content += keys;
              }
            }
            break;
        }
        this.content = content

        this.payOrderInfo.amount = this.content
        // console.log(this.payOrderInfo.amount);
      },
      // 校验付款码是否有效
      handleValidPayQrcode () {
        localStorage.removeItem('AUTHING') // 是否正在授权
        localStorage.removeItem('cacheToken') // 商户token
        validPayQrcode(this.query.qrCode).then(res => {
          console.log(res);
          if (res.codeStatus === 1) {
            this.payInfo = res
          }
        }).catch(() => {
          this.$router.push({ name: config.errorPageRouteName, params: { errInfo: "付款码已失效，无法支付！" } })
        });
      },
      // 生成订单 -> 获取商户token -> 支付
      createOrder () {
        let amount = Number(this.payOrderInfo.amount)
        if (!amount) {
          alert('请输入付款金额！')
          return
        }
        localStorage.removeItem('AUTHING') // 是否正在授权
        localStorage.removeItem('cacheToken') // 商户token
        localStorage.setItem('amount', amount)
        localStorage.setItem('memo', this.payOrderInfo.memo)
        console.log('*生成订单参数------------------start*');
        console.log('qrCode', this.query.qrCode);
        console.log('bizType', this.payInfo.bizType);
        console.log('amount', amount);
        console.log('memo', this.payOrderInfo.memo);
        console.log('secret', this.payInfo.secret);
        console.log('*支付参数------------------end*');
        qrcodePayment({
          code: 'bms_pay',
          qrCode: this.query.qrCode,
          bizType: this.payInfo.bizType,
          tenantId: 125,
          amount: amount,
          memo: this.payOrderInfo.memo,
          secret: this.payInfo.secret
        }).then(res => {
          console.log(res);
          if (res.orderState === 0 && res.payData) {
            let token = res.payData.split('hub/')[1]
            config.cacheToken = token;
            localStorage.setItem('cacheToken', token) // 商户token
            let openid = channelUserIdUtil.getChannelUserId()
            if (openid) {
              // 存在openid 根据token获取支付数据包  // 后续支付
              this.payMoney()
            } else {
              // 不存在openid， 重定向截取code获取openid
              this.redirectUrl()
            }
          }
        })
      },

      getOpenid (token) {
        const allQuery = Object.assign(
          {
            wayCode: 'WX_JSAPI'
          },
          this.searchToObject(), // code, state
          token // 商户token
        )
        getChannelUserId(allQuery).then(res => {

          //设置channelUserId (微信授权的openid,存储到localStorage)
          channelUserIdUtil.setChannelUserId(res);

          // // 生成订单获取token
          // this.createOrder(res)

        }).catch(res => {
          console.log(res);
        });
      },
      // 截取授权code
      searchToObject () {
        if (!window.location.search) {
          return {};
        }
        var pairs = window.location.search.substring(1).split("&"),
          result = {},
          pair,
          i;
        for (i in pairs) {
          if (pairs[i] === "") continue;
          pair = pairs[i].split("=");
          result[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);
        }
        return result;
      },
      setPayOrderInfo (isAutoPay) {
        const that = this
        getPayOrderInfo().then(res => {
          that.payOrderInfo = res

          if (isAutoPay) {
            that.pay()
          }
        }).catch(res => {
          that.$router.push({ name: config.errorPageRouteName, params: { errInfo: res.msg } })
        });
      },
      redirectUrl () {
        localStorage.setItem('AUTHING', true)
        localStorage.setItem('CUSTOM_PAY', true)
        getRedirectUrl().then(res => {
          console.log(res);
          location.href = res;
        }).catch(res => {
          this.$router.push({ name: config.errorPageRouteName, params: { errInfo: res.msg } })
        });
      },


      // 支付事件
      payMoney: function () {
        console.log('*支付参数------------------start*');
        console.log('amount', this.payOrderInfo.amount);
        console.log('token', config.cacheToken);
        console.log('channelUserId', channelUserIdUtil.getChannelUserId());
        console.log('*支付参数------------------end*');
        // 该函数执行效果慢
        let that = this;
        getPayPackage(Number(this.payOrderInfo.amount)).then(res => {

          if (res.code != '0') {
            return alert(res.msg);
          }

          if (res.data.orderState != 1) { //订单不是支付中，说明订单异常
            return alert(res.data.errMsg);
          }

          that.resData = res.data;
          console.log('支付数据包：', res.data);
          if (typeof WeixinJSBridge == "undefined") {
            if (document.addEventListener) {
              document.addEventListener('WeixinJSBridgeReady', that.onBridgeReady, false);
            } else if (document.attachEvent) {
              document.attachEvent('WeixinJSBridgeReady', that.onBridgeReady);
              document.attachEvent('onWeixinJSBridgeReady', that.onBridgeReady);
            }
          } else {
            console.log('支付...');
            that.onBridgeReady();
          }

        }).catch(res => {
          that.$router.push({ name: config.errorPageRouteName, params: { errInfo: res.msg } })
        });
      },


      /* 唤醒微信支付*/
      onBridgeReady () {
        console.log('唤醒微信支付');
        localStorage.removeItem('CUSTOM_PAY')
        localStorage.removeItem('AUTHING')
        localStorage.removeItem('cacheToken')
        localStorage.removeItem('amount')
        localStorage.removeItem('memo')
        let that = this;

        // eslint-disable-next-line no-undef
        WeixinJSBridge.invoke(
          'getBrandWCPayRequest', JSON.parse(that.resData.payInfo),
          function (res) {
            if (res.err_msg == "get_brand_wcpay_request:ok") {
              // 使用以上方式判断前端返回,微信团队郑重提示：
              //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
              alert('支付成功！');
              window.WeixinJSBridge.call('closeWindow')

            }
            if (res.err_msg == "get_brand_wcpay_request:cancel") {
              alert("支付取消");
              window.WeixinJSBridge.call('closeWindow')
            }
            if (res.err_msg == "get_brand_wcpay_request:fail") {
              alert('支付失败:' + JSON.stringify(res))
              window.WeixinJSBridge.call('closeWindow')
            }
            if (res.err_msg == "total_fee") {
              alert('缺少参数')
              window.WeixinJSBridge.call('closeWindow')
            }
          }
        );
      }



    }


  }
</script>
<style lang="css" scoped>
  @import './pay.css';
  .header {
    display: flex;
    flex-direction: column;
    height: auto;
    padding-bottom: 10px;
  }
  .header-img {
    width: 140px;
    height: auto;
    border-radius: 0;
  }
  .plus-input {
    display: flex;
    align-items: center;
  }
  .remark-hui {
    max-width: 75vw;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-all;
    white-space: nowrap;
  }
  .S-img {
    height: 60%;
    display: flex;
    align-items: center;
  }
  .S-img img {
    height: 75%;
  }
  .header-text {
    width: 100%;
    text-align: center;
    margin-top: 30px;
    font-size: 40px;
    /* height: 40px; */
  }
  .input-c-div-1 {
    padding-left: 30px;
  }
  .placeholder {
    font-size: 52px;
  }
</style>